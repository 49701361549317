import React, { Component } from "react";
import {
    Route,
    NavLink,
    BrowserRouter
} from "react-router-dom";
import logo from './images/logo-icch.jpeg';

import { LinkContainer } from 'react-router-bootstrap';

import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import Form from 'react-bootstrap/Form';
// import FormControl from 'react-bootstrap/FormControl';
// import Button from 'react-bootstrap/Button';

import Footer from "./sections/Footer";

import Home from "./pages/Home";
import Speakers from "./pages/program/Speakers";
import Conferences from "./pages/program/Conferences";
import Scopes from "./pages/papers/Scopes";
import Publication from "./pages/Publication";
import AboutPurwokerto from "./pages/hospitality/AboutPurwokerto";
import Accomodation from "./pages/hospitality/Accomodation";
import TourTravel from "./pages/hospitality/TourTravel";
import Committee from "./pages/about_us/Committee";
import ContactUs from "./pages/about_us/ContactUs";
import Information from "./pages/registration/Information";
import Poster from "./pages/registration/Poster";
import CallForPapers from "./pages/CallForPapers";
// import Publication from "./pages/Publication";
// import Page from "./sections/Page";
 
class Main extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Container className="pt-3">
            <h1><img src={logo} alt="Logo ICCH" width="300px"/></h1>
          </Container>
          <Navbar expand="lg" className="icch-navbar">
            {/* <Navbar.Brand exact to ="/">ICCH</Navbar.Brand> */}
            <Container>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <NavLink exact to="/" className="nav-link">Home</NavLink>
                  <NavDropdown title="Program" id="basic-nav-dropdown">
                    <LinkContainer to ="/program/speakers">
                      <NavDropdown.Item>Speakers</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to ="/program/conferences">
                      <NavDropdown.Item>Conferences</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  <NavLink exact to="/call-for-papers" className="nav-link">Call For Papers</NavLink>
                  <NavDropdown title="Paper Submission" id="basic-nav-dropdown" hidden >
                    <LinkContainer to ="/program/speakers" hidden>
                      <NavDropdown.Item>Submit Absctract</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to ="/program/speakers" hidden>
                      <NavDropdown.Item>Abstract Guidelines</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to ="/program/speakers" hidden>
                      <NavDropdown.Item>Fullpaper Guidelines</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to ="/program/speakers" hidden>
                      <NavDropdown.Item>Presentation Guidelines</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to ="/papers/call-for-papers" hidden >
                      <NavDropdown.Item>Call For Papers</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to ="/papers/scopes" hidden>
                      <NavDropdown.Item>Scopes</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to ="/program/speakers" hidden>
                      <NavDropdown.Item>Publication</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  <NavLink exact to="/publication" className="nav-link">Publication</NavLink>
                  <NavDropdown title="Registration" id="basic-nav-dropdown">
                    <LinkContainer to ="/registration/information">
                      <NavDropdown.Item>Information</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to ="/registration/poster">
                      <NavDropdown.Item>Poster</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/hospitality/accomodation" hidden>
                      <NavDropdown.Item>Terms & Conditions</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/hospitality/tour-n-travel" hidden>
                      <NavDropdown.Item>Registration Form</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/hospitality/tour-n-travel" hidden>
                      <NavDropdown.Item>Visa Information</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/hospitality/tour-n-travel" hidden>
                      <NavDropdown.Item>Letter of Invitation</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  <NavDropdown title="Hospitality" id="basic-nav-dropdown" hidden>
                    <LinkContainer to ="/hospitality/about-purwokerto">
                      <NavDropdown.Item>About Purwokerto</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/hospitality/accomodation">
                      <NavDropdown.Item>Accomodation</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/hospitality/tour-n-travel">
                      <NavDropdown.Item>Tour & Travel</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  <NavDropdown title="About Us" id="basic-nav-dropdown">
                    <LinkContainer to ="/about-us/committee">
                      <NavDropdown.Item>Committee</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/about-us/contact-us">
                      <NavDropdown.Item>Contact Us</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Container className="content pt-3">
            <Route exact path="/" component={Home} />
            <Route path="/program/speakers" component={Speakers} />
            <Route path="/program/conferences" component={Conferences} />
            <Route path="/call-for-papers" component={CallForPapers} />
            <Route path="/papers/scopes" component={Scopes} />
            <Route path="/publication" component={Publication} />
            <Route path="/registration/information" component={Information} />
            <Route path="/registration/poster" component={Poster} />
            <Route path="/hospitality/about-purwokerto" component={AboutPurwokerto} />
            <Route path="/hospitality/accomodation" component={Accomodation} />
            <Route path="/hospitality/tour-n-travel" component={TourTravel} />
            <Route path="/about-us/committee" component={Committee} />
            <Route path="/about-us/contact-us" component={ContactUs} />
          </Container>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}
 
export default Main;