import React, { Component } from "react";
import PageTitle from "../components/PageTitle";
import TwoRows from "../components/TwoRows";
 
class ImportantDates extends Component {
  render() {
    return (
      <div>
        <PageTitle title="Important Dates" />
        <table className="table table-sm">
          <tbody>
            <TwoRows first_column="Abstracts Submission" second_column="April 1 - July 31, 2019" />
            <TwoRows first_column="Early Bird Registration" second_column="April 1 - July 24, 2019" />
            <TwoRows first_column="Late Bird Registration" second_column="July 25 - July 31, 2019" />
            <TwoRows first_column="Full Paper Submission" second_column="August 5, 2019" />
            <TwoRows first_column="Exhibition Poster Competition" second_column="October 8, 2019" />
            <TwoRows first_column="Announcement of the winner of poster competition" second_column="October 9, 2019" />
          </tbody>
        </table>
      </div>
    );
  }
}
 
export default ImportantDates;