import React, { Component } from "react";

class PageTitle extends Component {
  render() {
    return (
      <div className="page-title">
        <h2>{this.props.title}</h2>
      </div>
    )
  }
}

export default PageTitle;