import React, {Component} from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import ContactCard from '../components/ContactCard';

import LogoUhb from '../images/logo-uhb-200.png';

class Footer extends Component {
  render() {
    const contacts = [
      {
        title: "SCIENTIFIC",
        name: "Azka Fathiyatir Rizqillah",
        phone: "+62 815 7500 0765 / +61 422 627 478 (WA only)",
        email: "azkafathiyatir@uhb.ac.id"
      },
      {
        title: "REGISTRATION",
        name: "Mariah Ulfah",
        phone: "+62 813 2708 7508",
        email: "mariahulfah@uhb.ac.id"
      },
      {
        title: "SECRETARIAT",
        name: "Rosi Kurnia Sugiharti",
        phone: "+62 856 4768 895",
        email: "rosikurniasugiharti@uhb.ac.id"
      },
    ]
    return (
      <div className="footer pt-5">
        <Container>
          <Row>
            <div className="col-sm-12 col-md">
              <div><img src={LogoUhb} alt="Logo ICCH" width="128px"/></div>
              <div>Faculty of Health</div>
              <div>Harapan Bangsa University</div>
              <p>
                Jl Raden Patah No 100 Ledug, Kembaran, Banyumas 53182 | Indonesia <br/>
                <a href="http://www.uhb.ac.id" target="_blank" rel="noopener noreferrer" className="contrast-link">www.uhb.ac.id</a>
              </p>
            </div>
            <div className="col-sm-12 col-md">
              <h5 className="footer-header">Contact</h5>
              {
                contacts.map((contact, index) => {
                  return <ContactCard key={index} contact={contact} />
                })
              }

            </div>
          </Row>
          <Row className="copyright-row">
            <div className="col"><small>Copyright 2019 &copy; - Harapan Bangsa University</small></div>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Footer;