import React, { Component } from "react";
import PageTitle from "../../components/PageTitle";
import OneRow from "../../components/OneRow";

class Committee extends Component {
  render() {
    return (
      <div>
        <PageTitle title="Committee" />
        <table className="table table-sm">
          <tbody>
            <OneRow text="Organizational Advisor" class="bg-warning" />
            <OneRow text="Yadi Fakhruzein Terang Jaya, S.E., MM, Harapan Bangsa University" />
            <OneRow text="Iis Setiawan Mangku Negara, S.Kom., M.T.I, Harapan Bangsa University" />
            <OneRow text="dr. Pramesti Dewi, M.Kes, Harapan Bangsa University" />
            <OneRow text="Supervisor" class="bg-warning" />
            <OneRow text="Martyarini Budi Setyawati, S.Kep, Ners, M.Kep, Harapan Bangsa University" />
            <OneRow text="Yuris Tri Naili, S.H, KN.,MH, Harapan Bangsa University" />
            <OneRow text="Murniati,S.Kep.Ns.,M.Kep, Harapan Bangsa University" />
            <OneRow text="Chairman" class="bg-warning" />
            <OneRow text="Ema Wahyu Ningrum, SST, M.Kes, Harapan Bangsa University" />
            <OneRow text="Secretary" class="bg-warning" />
            <OneRow text="Mariah Ulfah, S.SiT, M.Kes, Harapan Bangsa University" />
            <OneRow text="Treasurer" class="bg-warning" />
            <OneRow text="Rr. Farida Istiningrum, SE.,MM, Harapan Bangsa University" />
            <OneRow text="Steering Committee (International Advisory Board)" class="bg-warning" />
            <OneRow text="Rosie Stenhouse, Ph.D, Edinburgh University, United Kingdom" />
            <OneRow text="Norenia T Dao-Ayen, Ph.D, RN, Saint Louis University, Phillippines" />
            <OneRow text="Thanee Glomjai, RN, M.SN, PhD, Boromarajonani College of Nursing, Thailand" />
            <OneRow text="Prof. DR. Rifda Nauvalin, SP, M.Si, Jenderal Soedirman University, Indonesia" />
            <OneRow text="Fitri Haryati, DR. S.Kp, M.Kes, Gadjah Mada University, Indonesia" />
            <OneRow text="Scientific Committee" class="bg-warning" />
            <OneRow text="Thanee Glomjai, RN, M.SN, PhD, Boromarajonani College of Nursing, Thailand" />
            <OneRow text="Prof. DR. Rifda Nauvalin, SP, M.Si, Jenderal Soedirman University, Indonesia" />
            <OneRow text="Fitri Haryati, DR. S.Kp, M.Kes, Gadjah Mada University, Indonesia" />
            <OneRow text="Dr. Debie Dahlia, MHSM, ETN, Indonesia University, Indonesia" />
            <OneRow text="Suhartini, S.Kep., MNS, Ph.D, Diponegoro University, Indonesia" />
            <OneRow text="Technical Committee (Editorial Board)" class="bg-warning" />
            <OneRow text="Editor in Chief" class="bg-warning" />
            <OneRow text="Ns. Azka Fathiyatir R, S.Kep, Ners, MN, Harapan Bangsa University" />
            <OneRow text="Editors" class="bg-warning" />
            <OneRow text="Fauziah Hanum Nur Adriyani, S.ST, M.Keb., Harapan Bangsa University" />
            <OneRow text="Ikit Netra Wirakhmi, SST, M.Kes, Harapan Bangsa University" />
            <OneRow text="Dwi Novitasari, S.Kep, Ners, M.SC, Harapan Bangsa University" />
            <OneRow text="Tri Pujiani, M.Pd., Harapan Bangsa University" />
            <OneRow text="Dina Febrina, M.Farm., Apt., Harapan Bangsa University" />
            {/* <OneRow text="Mariah Ulfah, S.SiT, M.Kes, Harapan Bangsa University" />
            <OneRow text="Rosi Kurnia Sugiharti, SST, M.Kes, Harapan Bangsa University" />
            <OneRow text="Anggit Wirasto, S.Si, M.Eng, Harapan Bangsa University" />
            <OneRow text="Retno Agus Setiawan, S.Kom., M.T, Harapan Bangsa University" />
            <OneRow text="Barlian K, S.Pd., M.Hum., Harapan Bangsa University" />
            <OneRow text="Madyo Maryoto, S.Kep., Ns., MNS, Harapan Bangsa University" />
            <OneRow text="Susilo Rini, S.ST, M.Kes, Harapan Bangsa University" />
            <OneRow text="Ikhwan Yuda Kusuma, M.Si, Apt, Harapan Bangsa University" />
            <OneRow text="Linda Yanti, SST, M.Keb, Harapan Bangsa University" />
            <OneRow text="Etika Dewi Cahyaningrum, SST, M.Kes, Harapan Bangsa University" />
            <OneRow text="Noor Rohmah Ida Ayu TP, S.Kep, Ners. M.Kep, Harapan Bangsa University" />
            <OneRow text="Rani Prabandari, M.Farm, Apt, Harapan Bangsa University" />
            <OneRow text="Peppy Octaviani DM, S.Farm, Apt, MH, M.Sc, Harapan Bangsa University" />
            <OneRow text="Aris Riyanto, ST, Harapan Bangsa University" /> */}

          </tbody>
        </table>
      </div>
    );
  }
}
 
export default Committee;