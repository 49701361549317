import React, { Component } from "react";
import PageTitle from "../../components/PageTitle";
import ContactCard from '../../components/ContactCard';

class ContactUs extends Component {
  render() {
    const contacts = [
      {
        title: "SCIENTIFIC",
        name: "Azka Fathiyatir Rizqillah",
        phone: "+62 815 7500 0765 / +61 422 627 478 (WA only)",
        email: "azkafathiyatir@uhb.ac.id"
      },
      {
        title: "REGISTRATION",
        name: "Mariah Ulfah",
        phone: "+62 813 2708 7508",
        email: "mariahulfah@uhb.ac.id"
      },
      {
        title: "SECRETARIAT",
        name: "Rosi Kurnia Sugiharti",
        phone: "+62 856 4768 895",
        email: "rosikurniasugiharti@uhb.ac.id"
      },
    ]
    return (
      <div>
        <PageTitle title="Contact Us" />
        <div className="px-2 py-2 bg-secondary text-white mb-2">
          {
            contacts.map((contact, index) => {
              return <ContactCard key={index} contact={contact} />
            })
          }
        </div>
      </div>
    );
  }
}
 
export default ContactUs;