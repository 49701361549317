import React, { Component } from "react";
import PageTitle from "../../components/PageTitle";
 
class Scopes extends Component {
  render() {
    return (
      <div>
        <PageTitle title="Scopes" />

        <div>
          <ul>
            <li>Nursing and Midwifery</li>
            <li>Pharmaceutical Sciences</li>
            <li>Public Health</li>
            <li>Applied Health Sciences related to health development</li>
          </ul>
        </div>
      </div>
    );
  }
}
 
export default Scopes;