import React, { Component } from "react";
import PageTitle from "../../components/PageTitle";
import Image from 'react-bootstrap/Image';

class Poster extends Component {
  render() {
    const banners = {
      banner_01 : require("../../images/banners/poster-01.jpeg"),
      banner_02 : require("../../images/banners/poster-02.jpeg")
    }
    return (
      <div className="pb-5">
        <PageTitle title="International Research Poster and Innovation Competition" />
        <Image src={banners.banner_01} className="banner-image img-fluid" />
        <Image src={banners.banner_02} className="banner-image img-fluid" />

        <hr/>
        <h4>Poster Guidelines</h4>
        Download poster guidelines <a href="https://drive.google.com/file/d/11i82UQsZ9pkfHn_icODWy5vw5S9ZtD-7/view?usp=sharing" target="_blank" rel="noopener noreferrer">here</a>.
        <hr/>
        <h4>How to Register</h4>
        <ol>
          <li>Fill your details in the registration form <a href="https://forms.gle/tc3PJTA96oRNXArk6" target="_blank" rel="noopener noreferrer">(click here)</a>.</li>
          <li>Check your email for our confirmation.</li>
          <li>Make your payment on one of our bank account (listed below)</li>
          <li>Upload your payment proof to <a href="https://forms.gle/sG5Ku9e6G24vgCyu5" target="_blank" rel="noopener noreferrer">this form</a>.</li>
        </ol>
        <h6>List of Bank Accounts</h6>
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>Bank Name</th>
              <th>Account Number</th>
              <th>Account Name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Bank Mandiri</td>
              <td>135-000209-5527</td>
              <td>Yayasan Pendidikan Dwi Puspita</td>
            </tr>
            <tr>
              <td>Bank BTN</td>
              <td>00033-01-30-300300-5</td>
              <td>Yayasan Pendidikan Dwi Puspita</td>
            </tr>
            <tr>
              <td>Bank Jateng</td>
              <td>1-003-00600-6</td>
              <td>Yayasan Pendidikan Dwi Puspita</td>
            </tr>
          </tbody>
        </table>
        
      </div>
    );
  }
}
 
export default Poster;