import React, {Component} from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'react-bootstrap/Image';

class SpeakerCard extends Component {
  render() {
    return (
      <div className="speaker-card-container text-center">
        <div className="text-center">
          {this.props.person.image ? (
            <div className="profile-image-container mx-auto">
              <Image src={this.props.person.image} className="speaker-image" roundedCircle />
            </div>
            ) : (''
          )}
          <div className="text-center">
            <h5><em>{this.props.person.topic}</em></h5>
            <h5>{this.props.person.name}</h5>
            <h6>{this.props.person.position}</h6>
            <span>{this.props.person.organization}</span>
            <div className="py-3">{this.props.person.shortbio}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default SpeakerCard;