import React, { Component } from "react";
import PageTitle from "../components/PageTitle";
 
class Publication extends Component {
  render() {
    return (
      <div>
        <PageTitle title="Publication" />
        <div>
          <p>
            Proceeding book will be published by <a href="http://atlantis-press.com/proceedings/all" target="_blank" rel="noopener noreferrer">Atlantis Press</a>, will be indexed by Scopus and Thomson Reuters. <br/>
            Selected papers will be published in indexed journal or national accredited journals.
          </p>
          {/* <ul>
            <li>Prosiding terindeks SCOPUS / Thomson Reuters</li>
            <li>Jurnal Nasional Terakreditasi</li>
            <li>Prosiding Internasional ber-ISBN</li>
          </ul> */}
        </div>
      </div>
    );
  }
}
 
export default Publication;