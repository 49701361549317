import React, { Component } from "react";
import PageTitle from "../../components/PageTitle";
import RegFlow from '../../images/registration-flow.jpeg';

class Information extends Component {
  render() {
    return (
      <div className="pb-5">
        <PageTitle title="Registration Information" />
        <div><img src={RegFlow} alt="Registration Flow" className="img-fluid" /></div>

        <h4>Registration Fee</h4>
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th rowSpan="2"></th>
              <th colSpan="2">Local</th>
              <th colSpan="2">International</th>
            </tr>
            <tr>
              <th className="bg-light">Student</th>
              <th>Lecturer</th>
              <th className="bg-light">Student</th>
              <th>Lecturer</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th colSpan="5" className="bg-warning">Early Bird (July 24, 2019)</th>
            </tr>
            <tr>
              <th>Participant + Presenter (oral/poster)</th>
              <td className="bg-light">IDR 800,000</td>
              <td>IDR 1,200,000</td>
              <td className="bg-light">USD 56</td>
              <td>USD 83</td>
            </tr>
            <tr>
              <th>Participant Only</th>
              <td className="bg-light">IDR 400,000</td>
              <td>IDR 500,000</td>
              <td className="bg-light">USD 29</td>
              <td>USD 36</td>
            </tr>
            <tr>
              <th colSpan="5" className="bg-warning">Late Bird (July 31, 2019)</th>
            </tr>
              <tr>
              <th>Participant + Presenter (oral/poster)</th>
              <td className="bg-light">IDR 1,000,000</td>
              <td>IDR 1,600,000</td>
              <td className="bg-light">USD 69</td>
              <td>USD 110</td>
            </tr>
            <tr>
              <th>Participant Only</th>
              <td className="bg-light">IDR 500,000</td>
              <td>IDR 600,000</td>
              <td className="bg-light">USD 36</td>
              <td>USD 43</td>
            </tr>
            <tr>
              <th colSpan="5" className="bg-warning">Onsite (October 8-9, 2019)</th>
            </tr>
            {/* <tr>
              <th>Participant + Presenter (oral/poster)</th>
              <td className="bg-light">IDR 1,100,000</td>
              <td>IDR 1,700,000</td>
              <td className="bg-light">USD 78</td>
              <td>USD 120</td>
            </tr> */}
            <tr>
              <th>Participant + Presenter (oral/poster)</th>
              <td className="bg-light"><i>not available</i></td>
              <td><i>not available</i></td>
              <td className="bg-light"><i>not available</i></td>
              <td><i>not available</i></td>
            </tr>
            <tr>
              <th>Participant Only</th>
              <td className="bg-light">IDR 600,000</td>
              <td>IDR 700,000</td>
              <td className="bg-light">USD 43</td>
              <td>USD 50</td>
            </tr>
          </tbody>
        </table>
        {/* <table className="table table-striped table-sm">
          <tbody>
            <tr>
              <th colSpan="2" rowSpan="2"></th>
              <th colSpan="2">Early Registration (June 10th, 2019)</th>
              <th colSpan="2">Late Registration (September 9th, 2019)</th>
            </tr>
            <tr>
              <td>Presenter(oral/poster)</td>
              <td>Participant Only</td>
              <td>Presenter(oral/poster)</td>
              <td>Participant Only</td>
            </tr>
            <tr>
              <td rowSpan="2">Local</td>
              <td>Student</td>
              <td>IDR 800,000</td>
              <td>IDR 200,000 (UHB) <br/>IDR 300,000</td>
              <td>IDR 1,000,000</td>
              <td>IDR 200,000 (UHB) <br/>IDR 300,000</td>
            </tr>
            <tr>
              <td>Lecturer</td>
              <td>IDR 1,200,000</td>
              <td>IDR 350,000</td>
              <td>IDR 1,600,000</td>
              <td>IDR 350,000</td>
            </tr>
            <tr>
              <td rowSpan="2">International</td>
              <td>Student</td>
              <td>USD 56</td>
              <td>USD 21</td>
              <td>USD 69</td>
              <td>USD 21</td>
            </tr>
            <tr>
              <td>Lecturer</td>
              <td>USD 83</td>
              <td>USD 25</td>
              <td>USD 110</td>
              <td>USD 25</td>
            </tr>
          </tbody>
        </table> */}
        <div>
          Notes:
          <ul>
            <li>This fee doesn't include the cost of proceeding and journal publication</li>
            {/* <li>Mendapatkan seminar kit, snack 2x, makan 1x, sertifikat</li> */}
            {/* <li>Biaya tersebut belum termasuk biaya cetak prosiding dan publikasi jurnal, biaya akomodasi dan transportasi</li> */}
            {/* <li>Pelaksanaan bagi presenter adalah 2 hari, sedangkan partisipan hanya 1 hari</li> */}
          </ul>
        </div>
        <hr/>
        <h4>How to Register</h4>
        <ol>
          <li>Fill your details in the registration form <a href="https://docs.google.com/forms/d/e/1FAIpQLSe6ayuUvaYvpGQJu6GbfY-xh6mZqFydyDiJ5NrGtQAXvjfwNg/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">(click here)</a>.</li>
          <li>Check your email for our confirmation.</li>
          <li>Make your payment on one of our bank account (listed below)</li>
          <li>Sent your payment proof to registration.icch@uhb.ac.id, please use same email address as in your registration.</li>
        </ol>
        <h6>List of Bank Accounts</h6>
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>Bank Name</th>
              <th>Account Number</th>
              <th>Account Name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Bank Mandiri</td>
              <td>135-000209-5527</td>
              <td>Yayasan Pendidikan Dwi Puspita</td>
            </tr>
            <tr>
              <td>Bank BTN</td>
              <td>00033-01-30-300300-5</td>
              <td>Yayasan Pendidikan Dwi Puspita</td>
            </tr>
            <tr>
              <td>Bank Jateng</td>
              <td>1-003-00600-6</td>
              <td>Yayasan Pendidikan Dwi Puspita</td>
            </tr>
          </tbody>
        </table>
        {/* <iframe title="registration_form" src="https://docs.google.com/forms/d/e/1FAIpQLSe6ayuUvaYvpGQJu6GbfY-xh6mZqFydyDiJ5NrGtQAXvjfwNg/viewform?embedded=true" width="640" height="693" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe> */}
      </div>
    );
  }
}
 
export default Information;