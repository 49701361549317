import React, { Component } from "react";
import PageTitle from "../components/PageTitle";
 
class CallForPapers extends Component {
  render() {
    return (
      <div>
        <PageTitle title="Call for Papers" />

        <div className="text-justify">
          <p>Non-communicable diseases such as heart disease, stroke, high blood pressure, and diabetes increase continuously throughout the world, including in Indonesia. These diseases are chronic diseases which are not caused by viruses or bacteria and are not transmitted to other people. WHO says tens of millions of people in the world die caused by non-communicable diseases. The danger of this diseases is to attack humans before the age of 60 years, aka productive age (WHO, 2010). The latest results from the NCD Alliance, WHO, Imperial College, and The Lancet team, published in the September 2018 edition of The Lancet health journal, state that 40.5 million of the 56.9 million people in the world died from non-communicable diseases in 2016 (Irma, 2018).</p>
          <p>The results of the Basic Health Research in 2018 show that the prevalence of non-communicable diseases increased when compared to those in 2013, including cancer, stroke, chronic kidney disease, diabetes mellitus, and hypertension. The head of the Health Research and Development Agency, Siswanto, explained that the prevalence of cancer rose from 1.4% (Basic Health Research, 2013) to 1.8% in 2018. This is in accordance with the prevalence of stroke risen from 7% to 10.9%, while chronic kidney disease rose from 2% to 3.8%. Based on the examination of blood sugar, the prevalence of diabetes mellitus rose from 6.9% to 8.5%; and the results of blood pressure measurements, hypertension rose from 25.8 percent to 34.1 percent. The increase in the prevalence of non-communicable diseases is related to lifestyle including smoking, consumption of alcoholic beverages, physical activity, and consumption of fruits and vegetables (Moeloek, 2018).</p>
          <p>Non-communicable diseases need serious attention because these diseases can affect quality of life which will affect the productivity of the community. Therefore, we as academicians have responsible and concern on the importance of improving the quality of life for people with non-communicable diseases. Holding an international seminar is as an idea of international cooperation in the field of health education with foreign academicians and become a forum for researchers to be able to exchange information so that they can add insight and maturity to scientific thinking, open opportunities for scientific publications in reputable journals.</p>
        </div>

        <div className="text-justify">
          <p>The 1<sup>st</sup> International Conference on Community Health (ICCH 2019) welcomes abstracts from scholars, practitioners, and students for an important international conference, which will take place from October 8<sup>th</sup> - 9<sup>th</sup>, 2019, in Purwokerto, Central Java, Indonesia. This year's conference theme <strong>"Improving Quality of Life among People with Non Communicable Diseases (NCDs)"</strong> highlights the presentation fo new trends, advances and research in all areas of community health. The conference will bring together leading professionals, academicians, universities, industry experts from around the world.</p>
        </div>

        <PageTitle title="Scopes" />
        <div>
          <ul>
            <li>Nursing and Midwifery</li>
            <li>Pharmaceutical Sciences</li>
            <li>Public Health</li>
            <li>Applied Health Sciences related to health development</li>
          </ul>
        </div>

        <PageTitle title="Abstract & Manuscript Guideline" />
        <div className="pb-5 text-justify">
          <h4>Acceptance System</h4>
          <p>At this international conference, there will be 5 articles published in international journals, 10 accredited national journals, and international proceedings. Acceptance of papers is accepted online using the Open Conference System (OCS) management system. This OCS manages the process of registering participants, submitting articles, revising articles, payment gateways, until announcing the schedule for implementing activities. The submitted articles should follow the style house set by the committee. The style house is as follows.</p>

          <h5>General Guideline</h5>
          <ol>
            <li>Manuscript is a summary of research results.</li>
            <li>Manuscript has been written in the form of a word format according to the template provided.</li>
            <li>Manuscript is written in English with the letters Time New Roman font 12. The length of the manuscript is around 8-15 pages and typed 1 spaced.   Manuscript is word format sent to address email: icch.uhb.ac.id</li>
            <li>A page setting is 2 columns with the same column and the distance between columns is 5 mm, while the Title, Author Identity and Abstract are written in 1 column (table 1 column)</li>
            <li>The paper size is A4 with the width of the margins is 3.5 cm for the upper, lower and left borders, while the right is 2.0 cm.</li>
          </ol>

          <h5>Writing Sistematics</h5>
          <ol>
            <li>Initial part: title, author's name, institution, email, and abstract.</li>
            <li>Main part: introduction, literature review and development of hypotheses (if any), methods of research, results of research and discussion, and conclusions and suggestions (if any).</li>
            <li>Final part: Acknowledgments (if any), and references</li>
          </ol>

          <h5>Author Title and Name</h5>
          <ol>
            <li>Titles are printed in capital letters, bold with Times New Roman with font size 12, single spacing with a maximum number of words 15.</li>
            <li>The author's name is written under the title without a title, it should not be abbreviated, beginning with a capital letter, without beginning with the word "by", the order of the author is the first author followed by the second, third and so on.</li>
            <li>Name of college and e-mail address (e-mail) of all authors are written under the name of the author with the letters Times New Roman with font size 10.</li>
          </ol>

          <h5>Abstract</h5>
          <ol>
            <li>Abstract is written in English containing the core problems / background of research, ways of research / problem solving, and the results obtained. The abstract word is bold.</li>
            <li>The number of words in the abstract is not more than 250 words and typed 1 space.</li>
            <li>The abstract font is Times New Roman with font size10, presented on the left and flat right, presented in a paragraph, and written without indent at the beginning of the sentence.</li>
            <li>Abstract is equipped with Keyword consisting of 3-5 words which are the core of the description of abstract. The keyword word is bold.</li>
          </ol>

          <h5>General Rules for Manuscript Writing</h5>
          <ol>
            <li>Each subtitle is written in Times New Roman with font size 12 letters and is bold.</li>
            <li>The new paragraph is written indented with indent-first line 0.75 cm, between paragraphs not spaced.</li>
            <li>Foreign words are written in italics.</li>
            <li>All numbers are written with numbers, except at the beginning of sentences and integers less than ten must be spelled.</li>
            <li>Tables and figures must be clearly explained, and numbered.</li>
          </ol>

          <h5>Template</h5>
          You can download the template on this <a href="https://drive.google.com/file/d/1eUaGf5M3WghTbb1r5440dO3evZPvbJg0/view?usp=sharing" target="_blank" rel="noopener noreferrer">link.</a>
        </div>
      </div>
    );
  }
}
 
export default CallForPapers;