import React, { Component } from "react";
// import PageTitle from "../components/PageTitle";
import SpeakerCard from "../components/SpeakerCard";
import ImportantDates from "../components/ImportantDates";

import Carousel from 'react-bootstrap/Carousel';
import Countdown from 'react-countdown-now';
 
class Home extends Component {
  render() {
    const Completionist = () => <span>It's started!</span>;
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        return <Completionist />;
      } else {
        return <span className="countdown-container">{days} day(s), {hours}:{minutes}:{seconds} hours</span>
      }
    };
    const startdate = '08 Oct 2019 08:00:00';
    const chairman = {
      name: "Ema Wahyu Ningrum, SST, M.Kes",
      position: "Chairman",
      organization: "The 1st ICCH 2019",
      topic: null,
      image: require("../images/speakers/ema-wahyu.jpg")
    }
    const keynote_speaker = {
      name: "Rosie Stenhouse, Ph.D",
      position: null,
      organization: "Lecturer in Nursing Studies School of Health in Social Science Edinburgh University, UK",
      topic: null,
      image: require("../images/speakers/rosie-stenhouse.jpeg")
    }
    const plenary_speakers = [
      {
        name: "Norenia T. Dao-Ayen, Ph.D., RN,",
        position: null,
        organization: "Associate Dean School of Nursing Saint Louis University Phillipines",
        topic: null,
        image: require("../images/speakers/norenia.jpeg")
      },
      {
        name: "Thanee Glomjai, RN, M.S.N, Ph.D",
        position: null,
        organization: "Director, Baromarajonani College of Nursing Phayao-Thailand",
        topic: null,
        image: require("../images/speakers/thanee.jpeg")
      },
      // Assistive Technology to Improve Physical and Psychological Needs of NCDs' patients
      {
        name: "Dr. Rajeswari",
        position: null,
        organization: "Associate Professor & Head of Department of Electronics and Communication Engineering, Acharya Institute of Technology, Bangalore, India",
        topic: null,
        image: require("../images/speakers/rajeswari.jpg")
      },
      {
        name: "Ns. Martyarini Budi Setyawati, S.Kep, M.Kep",
        position: null,
        organization: "Lecturer in Nursing Studies Program, Health Faculty, Harapan Bangsa University, Indonesia",
        topic: null,
        image: require("../images/speakers/martyarini.jpeg")
      },
      {
        name: "Anggit Wirasto, M.Eng.",
        position: null,
        organization: "Lecturer in Information Technology, Faculty of Science and Technology, Harapan Bangsa University, Indonesia",
        topic: null,
        image: require("../images/speakers/anggit-wirasto.png")
      }
    ]
    return (
      <div>
        <div id="Carousel" className="py-3">
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require('../images/001.jpg')}
                width="800px"
                height="400px"
                alt="First slide"
                />
              <Carousel.Caption>
                <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require('../images/002.jpg')}
                width="800px"
                height="400px"
                alt="Third slide"
                />

              <Carousel.Caption>
                <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
        <div id="HomeImage" className="text-center mb-5">
          <h1 className="title-header">ICCH 2019</h1>
          <h4>The 1<sup>st</sup> International Conference on Community Health</h4>
          <h5>"Improving Quality of Life among People with Non Communicable Diseases (NCDs)"</h5>
          <p><strong>October 8<sup>th</sup> - 9<sup>th</sup>, 2019, Java Heritage Hotel, Purwokerto, Indonesia</strong></p>
          <Countdown date={startdate} renderer={renderer} />
        </div>
        <div id="ChairmanWords" className="py-3 text-justify">
          <SpeakerCard person={chairman}></SpeakerCard>
          <p>Dear Colleagues,</p>
          <p>On behalf of the international organizing commite, I am delighted to invite you to attend the 1st International Conference on Community Health: Improving Quality Of Life For People With Non Communicable Diseases (NCDs), to be held in Purwokerto, Indonesia, 8-9 October 2019. NCD, such as heart disease, stroke, high blood pressure, and diabetes increase continuously throughout the world, including in Indonesia. These diseases are chronic diseases not caused by viruses or bacteria and not transmitted to other people. WHO says tens of millions of people in the world die because of NCDs. These diseases mostly attack people with the age of less than 60 years, a.k.a productive age (WHO, 2010). The latest results conducted by the NCD Alliance, WHO, Imperial College, and The Lancet team, published in the September 2018 edition of The Lancet health journal, said that 40.5 million of 56.9 million people in the world died because of NCDs in 2016.  NCDs need serious attention because these NCDs can affect the quality of life which will affect the productivity of the community. Therefore, we as academics need to be involved in improving the quality of life in people with NCDs through organizing an international conference. International speakers will deliver material related to current scientific topics. The conference will be attended by participants from some countries presenting the results of their research. We thank to dr. Pramesti Dewi, M.Kes as the Rector of Harapan Bangsa University for her support. To all the committees who have worked well for the success of this event. Last but not least we would like to welcome all participants in Purwokerto, Indonesia in 2019.</p>

        </div>
        <div className="text-justify" hidden>
          <p>Non-communicable diseases such as heart disease, stroke, high blood pressure, and diabetes increase continuously throughout the world, including in Indonesia. These diseases are chronic diseases which are not caused by viruses or bacteria and are not transmitted to other people. WHO says tens of millions of people in the world die caused by non-communicable diseases. The danger of this diseases is to attack humans before the age of 60 years, aka productive age (WHO, 2010). The latest results from the NCD Alliance, WHO, Imperial College, and The Lancet team, published in the September 2018 edition of The Lancet health journal, state that 40.5 million of the 56.9 million people in the world died from non-communicable diseases in 2016 (Irma, 2018).</p>
          <p>The results of the Basic Health Research in 2018 show that the prevalence of non-communicable diseases increased when compared to those in 2013, including cancer, stroke, chronic kidney disease, diabetes mellitus, and hypertension. The head of the Health Research and Development Agency, Siswanto, explained that the prevalence of cancer rose from 1.4% (Basic Health Research, 2013) to 1.8% in 2018. This is in accordance with the prevalence of stroke risen from 7% to 10.9%, while chronic kidney disease rose from 2% to 3.8%. Based on the examination of blood sugar, the prevalence of diabetes mellitus rose from 6.9% to 8.5%; and the results of blood pressure measurements, hypertension rose from 25.8 percent to 34.1 percent. The increase in the prevalence of non-communicable diseases is related to lifestyle including smoking, consumption of alcoholic beverages, physical activity, and consumption of fruits and vegetables (Moeloek, 2018).</p>
          <p>Non-communicable diseases need serious attention because these diseases can affect quality of life which will affect the productivity of the community. Therefore, we as academicians have responsible and concern on the importance of improving the quality of life for people with non-communicable diseases. Holding an international seminar is as an idea of international cooperation in the field of health education with foreign academicians and become a forum for researchers to be able to exchange information so that they can add insight and maturity to scientific thinking, open opportunities for scientific publications in reputable journals.</p>
        </div>
        <div id="SpeakersPreview" className="py-3 bg-light">
          <div className="row">
            <div className="col mx-auto">
              <div className="text-center"><h2>Keynote Speaker</h2></div>
              <div className="mx-auto">
                <SpeakerCard person={keynote_speaker}></SpeakerCard>
              </div>
            </div>
          </div>
          <hr/>
          <div>
            <div className="text-center"><h2>Plenary Speakers</h2></div>
            <div className="row">
              {
                plenary_speakers.map((speaker, i) => {
                  return <div className="col-6" key={i}><SpeakerCard key={i} person={speaker} /></div>
                })
              }
            </div>
          </div>
        </div>
        <div className="py-3">
          <ImportantDates />
        </div>
      </div>
    );
  }
}
 
export default Home;