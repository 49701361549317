import React from "react";
import ReactDOM from "react-dom";
import Main from "./Main";
// import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.css";
import "holderjs";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fontawesome from '@fortawesome/fontawesome'
import { faPhoneSquare, faEnvelopeSquare } from '@fortawesome/fontawesome-free-solid'

fontawesome.library.add(faPhoneSquare, faEnvelopeSquare);
 
ReactDOM.render(
  <Main/>, 
  document.getElementById("root")
);