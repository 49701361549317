import React, { Component } from "react";

class OneRow extends Component {
  render() {
    return (
      <tr className={this.props.class}>
        <td>{this.props.text}</td>
      </tr>
    )
  }
}

export default OneRow;