import React, { Component } from "react";
import PageTitle from "../../components/PageTitle";
import SpeakerCard from "../../components/SpeakerCard";

class Speakers extends Component {
  render() {
    const keynote_speaker = {
      name: "Rosie Stenhouse, Ph.D",
      position: null,
      organization: "Lecturer in Nursing Studies School of Health in Social Science Edinburgh University, UK",
      topic: "Mental Health Issues and Caring Strategies on Non Communicable Diseases (NCDs) Patient",
      image: require("../../images/speakers/rosie-stenhouse.jpeg"),
      shortbio: "With a background in Social Science and mental health nursing, she joined Nursing Studies as a full-time lecturer in 2013. Her teaching focuses on research methods, mental health including a course the I developed on Contemporary issues in mental health: engagement through the arts, humanities and social science, and critical engagement with professional issues relating to working in healthcare organisations. She has developed a body of research driven by a commitment to social justice. She is also an associate director of the newly created Centre for Creative Relational Inquiry in the School of Health in Social Science, University of Edinburgh.  Moreover, she supervises PhD students from nursing and other social science disciplines who are using qualitative methodologies, and in particular narrative/discourse analytic methodologies. Topics are variable and current PhD supervisees are examining the gendered experience of caring, mental health nurses' role conceptualisation in relation to physical health care, HIV and medication adherence, professionalisation and caring, widening participation, maternal health care experiences."
    }
    const plenary_speakers = [
      {
        name: "Norenia T. Dao-Ayen, Ph.D., RN,",
        position: null,
        organization: "Associate Dean School of Nursing Saint Louis University Phillipines",
        topic: "Quality of Life of Patient With NCDs: Implication for Health Practice and Research",
        image: require("../../images/speakers/norenia.jpeg"),
        shortbio: "She is an associate dean at School of Nursing, Saint Louis University. She also becomes board of directors of Philippine Nursing Research Society. She has ever been a resource speaker for best practices in Nursing held by Embassy of Indonesia."
      },
      {
        name: "Thanee Glomjai, RN, M.S.N, Ph.D",
        position: null,
        organization: "Director, Baromarajonani College of Nursing Phayao-Thailand",
        topic: "Academic Services for Better Quality of Life of NCDs Patient in Communities",
        image: require("../../images/speakers/thanee.jpeg"),
        shortbio: "He is a director of Boromarajonani College of Nursing – Phayoa and also actively as a nursing lecturer in Thailand. He focuses on Nursing Administration. He got his doctoral degree at The University of Edinburgh with a background in Health Promotion. He has conducted a research project on alcohol consumption behaviours of young Thai people from the perspectives of stakeholders in Petchaburi Province, Thailand."
      },
      // Assistive Technology to Improve Physical and Psychological Needs of NCDs' patients
      {
        name: "Dr. Rajeswari",
        position: null,
        organization: "Associate Professor & Head of Department of Electronics and Communication Engineering, Acharya Institute of Technology, Bangalore, India",
        topic: "Assistive Technology to Improve Physical and Psychological Needs of NCDs' Patients",
        image: require("../../images/speakers/rajeswari.jpg")
      },
      {
        name: "Ns. Martyarini Budi Setyawati, S.Kep, M.Kep",
        position: null,
        organization: "Lecturer in Nursing Studies Program, Health Faculty, Harapan Bangsa University, Indonesia",
        topic: "Palliative Care on People with Non Communicable Diseases (NCDs)",
        image: require("../../images/speakers/martyarini.jpeg")
      },
      {
        name: "Anggit Wirasto, M.Eng.",
        position: null,
        organization: "Lecturer in Information Technology, Faculty of Science and Technology, Harapan Bangsa University, Indonesia",
        topic: null,
        image: require("../../images/speakers/anggit-wirasto.png")
      }
    ]
    return (
      <div>
        <PageTitle title="Speakers" />
        <div className="mx-auto detail-speaker-card">
          <SpeakerCard person={keynote_speaker}></SpeakerCard>
        </div>
        {
          plenary_speakers.map((speaker, i) => {
            return <div className="mx-auto detail-speaker-card" key={i}><SpeakerCard key={i} person={speaker} /></div>
          })
        }
      </div>
    );
  }
}
 
export default Speakers;