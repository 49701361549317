import React, { Component } from "react";
import PageTitle from "../../components/PageTitle";
import TwoRows from "../../components/TwoRows";

class Conferences extends Component {
  render() {
    return (
      <div>
        <PageTitle title="Conferences Schedule" />
        You can download the Program Book from this <a href="https://drive.google.com/open?id=1stU4x2QGpH4DB19LnhBzaj0Dnf9fraJc" target="_blank" rel="noopener noreferrer">link.</a>

        <table className="table table-sm">
          <tr>
            <th colSpan="2" className="bg-warning">Tuesday, October 8th, 2019</th>
          </tr>
          <TwoRows first_column="07:30 - 08:00" second_column="Re-registration" />
          <TwoRows first_column="08:00 - 08:30" second_column="Opening Ceremony" />
          <TwoRows first_column="08:30 - 09:00" second_column="Coffee Break" />
          <TwoRows first_column="09:00 - 10:30" second_column={[<em>Keynote Speaker</em>,<br />,<strong> Mental Health Issues and Caring Strategies on Non Communicable Diseases (NCDs) Patient </strong>, <br />, "Rosie Stenhouse", <br />, "Edinburgh University, UK"]} />
          <TwoRows first_column="10:30 - 12:00" second_column={[<em>Plenary Speakers</em>,<br />,<strong> Academic Services for Better Quality of Life of NCDs Patient in Communities </strong>, <br />, "Thanee Glomjai", <br />, "Boromarajonani College of Nursing, Thailand", <br />, <strong> Quality of Life of Patient With NCDs: Implication for Health Practice and Research </strong>, <br />, "Norenia T. Dao-Ayen", <br />, "Sain Louis University, Phillipine"]} />
          <TwoRows first_column="12:00 - 13:00" second_column="Lunch Break" />
          <TwoRows first_column="13:00 - 15:00" second_column={[<em>Plenary Speakers</em>,<br />,<strong> Palliative Care on People with Non Communicable Diseases (NCDs) </strong>, <br />, "Martyarini Budi Setyawati", <br />, "Harapan Bangsa University, Indonesia", <br />, <strong> Assistive Technology to Improve Physical and Psychological Needs of NCDs Patients </strong>, <br />, "Dr Rajeswari", <br />, "Acharya Institute of Technology, Bangalore, India" ]} />
          <TwoRows first_column="15:00 - 15:30" second_column="Coffee Break" />
          <TwoRows first_column="15:30 - 17:10" second_column="Oral Presentations & Poster Judgments" />
          <tr>
            <th colSpan="2" className="bg-warning">Wednesday, October 9th, 2019</th>
          </tr>
          <TwoRows first_column="08:00 - 09:40" second_column="Oral Presentations" />
          <TwoRows first_column="09:40 - 11:00" second_column="Oral & Poster Presentations Results" />

        </table>
      </div>
    );
  }
}
 
export default Conferences;