import React, {Component} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ContactCard extends Component {
  render() {
    return (
      <div className="contact-card">
        <div className="contact-card-header">
          {this.props.contact.title}
        </div>
        <div>
          {this.props.contact.name}
        </div>
        <div>
          <FontAwesomeIcon icon="phone-square" /> {this.props.contact.phone}
        </div>
        <div>
          <FontAwesomeIcon icon="envelope-square" /> {this.props.contact.email}
        </div>
      </div>
    )
  }
}

export default ContactCard;